body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(255, 255, 255, 0.92);
    background-color: #1f1f1f;
    margin: 0;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: 100%;
  width: 100%;
}

input:focus, textarea:focus, select:focus{
  outline: none;
}


p {
  padding: 0;
  margin:  0;
}

h1, h2, h3 {
  margin: 0;
}

a {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


pre {
  margin: 0;
}

button {
  cursor: pointer;
}

header {
  margin-bottom: auto;
}

footer {
  margin-top: auto;
}